import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const Weeding = () => (
  <CategoryPage
    title="Casamento"
    totalItems={4}
    s3Path={`portfolio/weeding/`}
    internalPath={`/casamentos/`}
    nextPath={`/smash-the-cake`}
    nextLabel="Smake the Cake"
    prevPath={`/recem-nascido`}
    prevLabel="Recém Nascido"
  />
)

export default Weeding;
